<template>
  <form action data-vv-scope="comisions-form">
    <v-row justify="center">
      <v-col cols="12" md="3" v-if="!disabled">
        <v-select
          :label="$t('commisions_type')"
          :items="comision_types"
          v-model="type"
          outlined
          dense
          data-vv-validate-on="blur"
          v-validate="'required'"
          :error-messages="errors.collect('comisions-form.commisions_type')"
          data-vv-name="commisions_type"
        ></v-select>
      </v-col>
    </v-row>
    <template v-if="type === 'from'">
      <!-- COMISIONES SEGUN PROCEDENCIA -->
      <v-row align="center" v-if="from_studio" justify="center">
        <v-col
          cols="12"
          md="5"
          class="py-0 primary--text"
          style="font-size: 11px"
        >
          <span v-html="$t('commisions.from_studio')" />
          <span v-html="$t('commisions.comision_is')" />
        </v-col>
        <v-col cols="4" md="1" class="py-0 px-1">
          <v-select
            class="big"
            :disabled="disabled"
            :items="comisionsType"
            outlined
            dense
            data-vv-validate-on="blur"
            v-validate="'required'"
            :error-messages="errors.collect('comisions-form.studio-type')"
            data-vv-name="studio-type"
            hide-details
            v-model="from_studio.type"
            size="15"
          >
            <template v-slot:item="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" md="2" class="py-0">
          <v-text-field
            :disabled="disabled"
            v-if="from_studio.type == 'percentage'"
            outlined
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            hide-details
            v-model="from_studio.valor"
            data-vv-validate-on="blur"
            :max="100"
            v-validate="'required|max_value:100'"
            :error-messages="errors.collect('comisions-form.studio-valor')"
            data-vv-name="studio-valor"
          ></v-text-field>
          <number-input-with-separators
            :disabled="disabled"
            v-else
            outlined
            dense
            hide-details
            :value="from_studio"
            valueKey="valor"
            rules="required|is_not:0€"
            ref="money_1"
          ></number-input-with-separators>
        </v-col>
        <v-col cols="12"><v-divider /></v-col
      ></v-row>
      <v-row align="center" v-if="from_tattooer" justify="center">
        <v-col
          cols="12"
          md="5"
          class="py-0 primary--text"
          style="font-size: 11px"
        >
          <span v-html="$t('commisions.from_tattooer')" />
          <span v-html="$t('commisions.comision_is')" />
        </v-col>
        <v-col cols="4" md="1" class="py-0 px-1">
          <v-select
            class="big"
            :disabled="disabled"
            :items="comisionsType"
            outlined
            dense
            data-vv-validate-on="blur"
            v-validate="'required'"
            :error-messages="errors.collect('comisions-form.studio-type')"
            hide-details
            v-model="from_tattooer.type"
            data-vv-name="studio-type"
            size="15"
          >
            <template v-slot:item="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" md="2" class="py-0">
          <v-text-field
            :disabled="disabled"
            v-if="from_tattooer.type == 'percentage'"
            outlined
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            hide-details
            v-model="from_tattooer.valor"
            data-vv-validate-on="blur"
            :max="100"
            v-validate="'required|max_value:100'"
            :error-messages="errors.collect('comisions-form.studio-valor')"
            data-vv-name="studio-valor"
          ></v-text-field>
          <number-input-with-separators
            :disabled="disabled"
            v-else
            outlined
            dense
            hide-details
            :value="from_tattooer"
            valueKey="valor"
            rules="required|is_not:0€"
            ref="money_1"
          ></number-input-with-separators>
        </v-col>
        <v-col cols="12"><v-divider /></v-col
      ></v-row>
    </template>
    <template v-else-if="type === 'price'">
      <!-- COMISIONES SEGUN PRECIO -->
      <v-row align="center" v-if="price_lower" justify="center">
        <v-col
          cols="12"
          md="6"
          class="py-0 primary--text"
          style="font-size: 11px"
        >
          <span v-html="$t('commisions.price_lower')" />

          <number-input-with-separators
            :disabled="disabled"
            outlined
            dense
            hide-details
            :value="commissions"
            valueKey="price"
            style="display: inline-block; max-width: 80px; margin: 0 8px"
            rules="required|is_not:0€"
            ref="price_1"
            @change="updatePrice()"
          ></number-input-with-separators>
          <span v-html="$t('commisions.comision_is')" />
        </v-col>
        <v-col cols="5" md="2" class="py-0">
          <v-select
            class="big"
            :disabled="disabled"
            :items="comisionsType"
            outlined
            dense
            data-vv-validate-on="blur"
            v-validate="'required'"
            :error-messages="errors.collect('comisions-form.studio-type')"
            hide-details
            v-model="price_lower.type"
            data-vv-name="studio-type"
            size="15"
          >
            <template v-slot:item="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="7" md="2" class="py-0">
          <v-text-field
            :disabled="disabled"
            v-if="price_lower.type == 'percentage'"
            outlined
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            hide-details
            v-model="price_lower.valor"
            data-vv-validate-on="blur"
            :max="100"
            v-validate="'required|max_value:100'"
            :error-messages="errors.collect('comisions-form.studio-valor')"
            data-vv-name="studio-valor"
          ></v-text-field>
          <number-input-with-separators
            :disabled="disabled"
            v-else
            outlined
            dense
            hide-details
            :value="price_lower"
            valueKey="valor"
            rules="required|is_not:0€"
            ref="money_1"
          ></number-input-with-separators>
        </v-col>
        <v-col cols="12"><v-divider /></v-col
      ></v-row>
      <v-row align="center" v-if="price_bigger" justify="center">
        <v-col
          cols="12"
          md="6"
          class="py-0 primary--text"
          style="font-size: 11px"
        >
          <span v-html="$t('commisions.price_bigger')" />
          <number-input-with-separators
            :disabled="disabled"
            outlined
            dense
            hide-details
            :value="commissions"
            valueKey="price"
            style="display: inline-block; max-width: 80px; margin: 0 8px"
            rules="required|is_not:0€"
            ref="price_2"
            @change="updatePrice()"
          ></number-input-with-separators>
          <span v-html="$t('commisions.comision_is')" />
        </v-col>
        <v-col cols="5" md="2" class="py-0">
          <v-select
            class="big"
            :disabled="disabled"
            :items="comisionsType"
            outlined
            dense
            data-vv-validate-on="blur"
            v-validate="'required'"
            :error-messages="errors.collect('comisions-form.studio-type')"
            hide-details
            v-model="price_bigger.type"
            data-vv-name="studio-type"
            size="15"
          >
            <template v-slot:item="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="7" md="2" class="py-0">
          <v-text-field
            :disabled="disabled"
            v-if="price_bigger.type == 'percentage'"
            outlined
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            hide-details
            v-model="price_bigger.valor"
            data-vv-validate-on="blur"
            :max="100"
            v-validate="'required|max_value:100'"
            :error-messages="errors.collect('comisions-form.studio-valor')"
            data-vv-name="studio-valor"
          ></v-text-field>
          <number-input-with-separators
            :disabled="disabled"
            v-else
            outlined
            dense
            hide-details
            :value="price_bigger"
            valueKey="valor"
            rules="required|is_not:0€"
            ref="money_1"
          ></number-input-with-separators>
        </v-col>
        <v-col cols="12"><v-divider /></v-col
      ></v-row>
    </template>
    <template v-else-if="type === 'both'">
      <!-- COMISIONES SEGUN PRECIO Y PROCEDENCIA-->
      <v-row class="primary--text" style="font-size: 11px" align="center">
        <v-col cols="12" md="4" v-html="$t('commisions.from_studio')"> </v-col>
        <v-col cols="12" md="8">
          <v-row align="center" v-if="studio_lower">
            <v-col md="8" cols="12">
              <span v-html="$t('commisions.and_lower')" />
              <number-input-with-separators
                :disabled="disabled"
                outlined
                dense
                hide-details
                :value="commissions"
                valueKey="price"
                style="display: inline-block; max-width: 80px; margin: 0 8px"
                rules="required|is_not:0€"
                ref="price_3"
                @change="updatePrice()"
              ></number-input-with-separators>
              <span v-html="$t('commisions.comision_is')" />
            </v-col>
            <v-col cols="5" md="2">
              <v-select
                class="big"
                :disabled="disabled"
                :items="comisionsType"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('comisions-form.studio-type')"
                hide-details
                v-model="studio_lower.type"
                data-vv-name="studio-type"
                size="15"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col md="2" cols="7">
              <v-text-field
                :disabled="disabled"
                v-if="studio_lower.type == 'percentage'"
                outlined
                dense
                type="number"
                v-on:keypress="isNumber($event)"
                hide-details
                v-model="studio_lower.valor"
                data-vv-validate-on="blur"
                :max="100"
                v-validate="'required|max_value:100'"
                :error-messages="errors.collect('comisions-form.studio-valor')"
                data-vv-name="studio-valor"
              ></v-text-field>
              <number-input-with-separators
                :disabled="disabled"
                v-else
                outlined
                dense
                hide-details
                :value="studio_lower"
                valueKey="valor"
                rules="required|is_not:0€"
                ref="money_1"
              ></number-input-with-separators>
            </v-col>
          </v-row>
          <v-row align="center" v-if="studio_bigger">
            <v-col cols="12" md="8">
              <span v-html="$t('commisions.and_bigger')" />
              <number-input-with-separators
                :disabled="disabled"
                outlined
                dense
                hide-details
                :value="commissions"
                valueKey="price"
                style="display: inline-block; max-width: 80px; margin: 0 8px"
                rules="required|is_not:0€"
                ref="price_4"
                @change="updatePrice()"
              ></number-input-with-separators>
              <span v-html="$t('commisions.comision_is')" />
            </v-col>
            <v-col cols="5" md="2">
              <v-select
                class="big"
                :disabled="disabled"
                :items="comisionsType"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('comisions-form.studio-type')"
                hide-details
                v-model="studio_bigger.type"
                data-vv-name="studio-type"
                size="15"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="7" md="2">
              <v-text-field
                :disabled="disabled"
                v-if="studio_bigger.type == 'percentage'"
                outlined
                dense
                type="number"
                v-on:keypress="isNumber($event)"
                hide-details
                v-model="studio_bigger.valor"
                data-vv-validate-on="blur"
                :max="100"
                v-validate="'required|max_value:100'"
                :error-messages="errors.collect('comisions-form.studio-valor')"
                data-vv-name="studio-valor"
              ></v-text-field>
              <number-input-with-separators
                :disabled="disabled"
                v-else
                outlined
                dense
                hide-details
                :value="studio_bigger"
                valueKey="valor"
                rules="required|is_not:0€"
                ref="money_1"
              ></number-input-with-separators>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="primary--text" style="font-size: 11px" align="center">
        <v-col md="4" cols="12" v-html="$t('commisions.from_tattooer')">
        </v-col>
        <v-col cols="12" md="8">
          <v-row align="center" v-if="tattooer_lower">
            <v-col cols="12" md="8">
              <span v-html="$t('commisions.and_lower')" />
              <number-input-with-separators
                :disabled="disabled"
                outlined
                dense
                hide-details
                :value="commissions"
                valueKey="price"
                style="display: inline-block; max-width: 80px; margin: 0 8px"
                rules="required|is_not:0€"
                ref="price_5"
                @change="updatePrice()"
              ></number-input-with-separators>
              <span v-html="$t('commisions.comision_is')" />
            </v-col>
            <v-col cols="5" md="2">
              <v-select
                class="big"
                :disabled="disabled"
                :items="comisionsType"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('comisions-form.tattooer-type')"
                hide-details
                v-model="tattooer_lower.type"
                data-vv-name="tattooer-type"
                size="15"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="7" md="2">
              <v-text-field
                :disabled="disabled"
                v-if="tattooer_lower.type == 'percentage'"
                outlined
                dense
                type="number"
                v-on:keypress="isNumber($event)"
                hide-details
                v-model="tattooer_lower.valor"
                data-vv-validate-on="blur"
                :max="100"
                v-validate="'required|max_value:100'"
                :error-messages="
                  errors.collect('comisions-form.tattooer-valor')
                "
                data-vv-name="tattooer-valor"
              ></v-text-field>
              <number-input-with-separators
                :disabled="disabled"
                v-else
                outlined
                dense
                hide-details
                :value="tattooer_lower"
                valueKey="valor"
                rules="required|is_not:0€"
                ref="money_1"
              ></number-input-with-separators>
            </v-col>
          </v-row>
          <v-row align="center" v-if="tattooer_bigger">
            <v-col cols="12" md="8">
              <span v-html="$t('commisions.and_bigger')" />
              <number-input-with-separators
                :disabled="disabled"
                outlined
                dense
                hide-details
                :value="commissions"
                valueKey="price"
                style="display: inline-block; max-width: 80px; margin: 0 8px"
                rules="required|is_not:0€"
                ref="price_6"
                @change="updatePrice()"
              ></number-input-with-separators>
              <span v-html="$t('commisions.comision_is')" />
            </v-col>
            <v-col cols="5" md="2">
              <v-select
                class="big"
                :disabled="disabled"
                :items="comisionsType"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('comisions-form.tattooer-type')"
                hide-details
                v-model="tattooer_bigger.type"
                data-vv-name="tattooer-type"
                size="15"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="7" md="2">
              <v-text-field
                :disabled="disabled"
                v-if="tattooer_bigger.type == 'percentage'"
                outlined
                dense
                type="number"
                v-on:keypress="isNumber($event)"
                hide-details
                v-model="tattooer_bigger.valor"
                data-vv-validate-on="blur"
                :max="100"
                v-validate="'required|max_value:100'"
                :error-messages="
                  errors.collect('comisions-form.tattooer-valor')
                "
                data-vv-name="tattooer-valor"
              ></v-text-field>
              <number-input-with-separators
                :disabled="disabled"
                v-else
                outlined
                dense
                hide-details
                :value="tattooer_bigger"
                valueKey="valor"
                rules="required|is_not:0€"
                ref="money_1"
              ></number-input-with-separators>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </form>
</template>
<script>
export default {
  name: "CommisionForm",
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  props: ["commissions", "disabled"],
  mounted() {
    if (this.commissions && this.commissions.type === null)
      this.changeComisions();
    else if (this.commissions) this.type = this.commissions.type;
  },
  watch: {
    type: {
      handler() {
        this.changeComisions();
      },
      deep: true,
    },
  },
  data() {
    return {
      type: null,
      comision_types: [
        { text: this.$t("comision_types.from"), value: "from" },
        { text: this.$t("comision_types.price"), value: "price" },
        { text: this.$t("comision_types.both"), value: "both" },
      ],
      comisionsType: [
        { text: "%", value: "percentage", style: "font-size: 10px" },
        { text: "€", value: "money" },
      ],
    };
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    updatePrice() {
      for (let i = 1; i <= 6; i++) {
        if (this.$refs["price_" + i]) this.$refs["price_" + i].change();
      }
    },

    async validate() {
      return await this.$validator.validateAll("comisions-form");
    },
    changeComisions() {
      //console.log("CHANGING");
      //console.log(this.commissions, this.type);
      if (typeof this.commissions !== "object" || this.commissions === null)
        this.commissions = {};
      if (this.commissions.type !== this.type) {
        //console.log("S'ha de actualitzar");
        switch (this.type) {
          case "from":
            //console.log("FROM");
            this.commissions.price = null;
            this.commissions.comisions = [
              {
                from: "studio",
                price_pos: "any",
                type: null,
                valor: null,
              },
              {
                from: "tattooer",
                price_pos: "any",
                type: null,
                valor: null,
              },
            ];
            break;
          case "price":
            //console.log("price");
            this.commissions.comisions = [
              {
                from: "any",
                price_pos: "lower",
                type: null,
                valor: null,
              },
              {
                from: "any",
                price_pos: "bigger",
                type: null,
                valor: null,
              },
            ];
            break;
          case "both":
            //console.log("both");
            this.commissions.comisions = [
              {
                from: "studio",
                price_pos: "lower",
                type: null,
                valor: null,
              },
              {
                from: "tattooer",
                price_pos: "lower",
                type: null,
                valor: null,
              },
              {
                from: "studio",
                price_pos: "bigger",
                type: null,
                valor: null,
              },
              {
                from: "tattooer",
                price_pos: "bigger",
                type: null,
                valor: null,
              },
            ];
            break;
        }
        this.commissions.type = this.type;
      }
    },
  },
  computed: {
    from_studio: {
      get() {
        if (this.commissions.type === "from")
          return this.commissions.comisions.find((c) => {
            return c.from === "studio" && c.price_pos === "any";
          });
      },
      set(value) {
        if (this.commissions.type === "from") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "studio" && c.price_pos === "any";
          });
          c = value;
        }
      },
    },
    from_tattooer: {
      get() {
        if (this.commissions.type === "from")
          return this.commissions.comisions.find((c) => {
            return c.from === "tattooer" && c.price_pos === "any";
          });
      },
      set(value) {
        if (this.commissions.type === "from") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "tattooer" && c.price_pos === "any";
          });
          c = value;
        }
      },
    },
    price_lower: {
      get() {
        if (this.commissions.type === "price")
          return this.commissions.comisions.find((c) => {
            return c.from === "any" && c.price_pos === "lower";
          });
      },
      set(value) {
        if (this.commissions.type === "price") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "any" && c.price_pos === "lower";
          });
          c = value;
        }
      },
    },
    price_bigger: {
      get() {
        if (this.commissions.type === "price")
          return this.commissions.comisions.find((c) => {
            return c.from === "any" && c.price_pos === "bigger";
          });
      },
      set(value) {
        if (this.commissions.type === "price") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "any" && c.price_pos === "bigger";
          });
          c = value;
        }
      },
    },
    studio_lower: {
      get() {
        if (this.commissions.type === "both")
          return this.commissions.comisions.find((c) => {
            return c.from === "studio" && c.price_pos === "lower";
          });
      },
      set(value) {
        if (this.commissions.type === "both") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "studio" && c.price_pos === "lower";
          });
          c = value;
        }
      },
    },
    studio_bigger: {
      get() {
        if (this.commissions.type === "both")
          return this.commissions.comisions.find((c) => {
            return c.from === "studio" && c.price_pos === "bigger";
          });
      },
      set(value) {
        if (this.commissions.type === "both") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "studio" && c.price_pos === "bigger";
          });
          c = value;
        }
      },
    },
    tattooer_lower: {
      get() {
        if (this.commissions.type === "both")
          return this.commissions.comisions.find((c) => {
            return c.from === "tattooer" && c.price_pos === "lower";
          });
      },
      set(value) {
        if (this.commissions.type === "both") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "tattooer" && c.price_pos === "lower";
          });
          c = value;
        }
      },
    },
    tattooer_bigger: {
      get() {
        if (this.commissions.type === "both")
          return this.commissions.comisions.find((c) => {
            return c.from === "tattooer" && c.price_pos === "bigger";
          });
      },
      set(value) {
        if (this.commissions.type === "both") {
          let c = this.commissions.comisions.find((c) => {
            return c.from === "tattooer" && c.price_pos === "bigger";
          });
          c = value;
        }
      },
    },
  },
};
</script>
